// Generated by Framer (114832d)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, useActiveVariantCallback, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["flis78DsA", "WpHtQDU24"];

const serializationHash = "framer-8mPnB"

const variantClassNames = {flis78DsA: "framer-v-17q2sc2", WpHtQDU24: "framer-v-1ddofmh"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {bounce: 0.2, delay: 0, duration: 0.4, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const humanReadableVariantMap = {Close: "WpHtQDU24", Default: "flis78DsA"}

const getProps = ({click, color, height, id, width, ...props}) => { return {...props, IXtQArV0V: color ?? props.IXtQArV0V ?? "var(--token-dade266e-403f-4443-9daf-d9d6dfc8a9db, rgb(33, 33, 33))", M5lKldedW: click ?? props.M5lKldedW, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "flis78DsA"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;color?: string;click?: any; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, IXtQArV0V, M5lKldedW, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "flis78DsA", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onTap5kd33l = activeVariantCallback(async (...args) => {
setGestureState({isPressed: false})
if (M5lKldedW) {const res = await M5lKldedW(...args);
if (res === false) return false;}
})

const sharedStyleClassNames = []

const scopingClassNames = cx(serializationHash, ...sharedStyleClassNames)

const ref1 = React.useRef<HTMLElement>(null)

const isDisplayed = () => {
if (baseVariant === "WpHtQDU24") return false
return true
}

const defaultLayoutId = React.useId()

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}><Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(scopingClassNames, "framer-17q2sc2", className, classNames)} data-framer-name={"Default"} data-highlight layoutDependency={layoutDependency} layoutId={"flis78DsA"} onTap={onTap5kd33l} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({WpHtQDU24: {"data-framer-name": "Close"}}, baseVariant, gestureVariant)}><motion.div className={"framer-n2ngot"} data-framer-name={"Top"} layoutDependency={layoutDependency} layoutId={"bFiTRNHmx"} style={{backgroundColor: IXtQArV0V, rotate: 0}} variants={{WpHtQDU24: {rotate: 45}}}/>{isDisplayed() && (<motion.div className={"framer-wwclba"} data-framer-name={"Middle"} layoutDependency={layoutDependency} layoutId={"f41Rlbr1g"} style={{backgroundColor: IXtQArV0V}}/>)}<motion.div className={"framer-154bhch"} data-framer-name={"Bottom"} layoutDependency={layoutDependency} layoutId={"qs5zCZYY9"} style={{backgroundColor: IXtQArV0V, rotate: 0}} variants={{WpHtQDU24: {rotate: -45}}}/></motion.div></Transition></Variants></LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-8mPnB.framer-orl0wf, .framer-8mPnB .framer-orl0wf { display: block; }", ".framer-8mPnB.framer-17q2sc2 { align-content: flex-end; align-items: flex-end; cursor: pointer; display: flex; flex-direction: column; flex-wrap: nowrap; height: 18px; justify-content: space-between; overflow: hidden; padding: 0px; position: relative; width: 24px; }", ".framer-8mPnB .framer-n2ngot, .framer-8mPnB .framer-154bhch { flex: none; height: 1px; overflow: hidden; position: relative; width: 100%; }", ".framer-8mPnB .framer-wwclba { flex: none; height: 1px; overflow: hidden; position: relative; width: 80%; }", ".framer-8mPnB.framer-v-1ddofmh .framer-n2ngot, .framer-8mPnB.framer-v-1ddofmh .framer-154bhch { left: calc(50.00000000000002% - 100% / 2); position: absolute; top: calc(50.00000000000002% - 1px / 2); }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 18
 * @framerIntrinsicWidth 24
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"WpHtQDU24":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"IXtQArV0V":"color","M5lKldedW":"click"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerzEDDbJINW: React.ComponentType<Props> = withCSS(Component, css, "framer-8mPnB") as typeof Component;
export default FramerzEDDbJINW;

FramerzEDDbJINW.displayName = "Button/Hamburger";

FramerzEDDbJINW.defaultProps = {height: 18, width: 24};

addPropertyControls(FramerzEDDbJINW, {variant: {options: ["flis78DsA", "WpHtQDU24"], optionTitles: ["Default", "Close"], title: "Variant", type: ControlType.Enum}, IXtQArV0V: {defaultValue: "var(--token-dade266e-403f-4443-9daf-d9d6dfc8a9db, rgb(33, 33, 33)) /* {\"name\":\"Black\"} */", title: "Color", type: ControlType.Color}, M5lKldedW: {title: "Click", type: ControlType.EventHandler}} as any)

addFonts(FramerzEDDbJINW, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})